import * as Sentry from '@sentry/react';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { getContracts } from '@/actions/contracts';
import { getPaymentData } from '@/actions/payments';
import { initScheduleViewState } from '@/actions/schedule/scheduleView';
import { getAllViewSettings, saveAllViewSettings } from '@/actions/settingsSaver/settingsSaver.ts';
import * as actionTypes from '@/constants/ActionTypes.js';
import { appVersion } from '@/constants/appVersion.js';
import browserHistory from '@/constants/browserHistory';
import { LOG_ROCKET_APP_ID, SENTRY_DSN } from '@/constants/envVariables';
import { AUTH_PROVIDER_MISMATCH, LOCATION_NOT_FOUND } from '@/constants/errorCodes.js';
import { APP_IN_MAINTANANCE_MODAL, INACTIVE_USER_MODAL, NO_LOCATIONS_MODAL } from '@/constants/modalTypes.js';
import { CUSTOM_FIELDS_SHOW, ENABLE_LOG_ROCKET, PERMISSIONS_VIEW_GET, SENTRY_ENABLE } from '@/constants/Permissions';
import { EMPLOYEES_CODE_NAMES } from '@/constants/Restrictions.js';
import { TRIAL_STATUS } from '@/constants/status.js';
import { USE_GOOGLE_OAUTH_LOGIN_TUTORIAL_ID } from '@/constants/tutorialsId';
import { getLocationGroups } from '@/redux-store/locationGroups';
import { storeLoggedAccounts } from '@/utils/accounts/accounts.utils';
import { sendGTMEvent } from '@/utils/analytics.js';
import { goToResetPasswordPage } from '@/utils/baseHelpers';
import { checkCookie, deleteCookie, getCookie, setCookie } from '@/utils/cookieHandlers.js';
import { sendCreateAccountEvent } from '@/utils/demoHelpers.js';
import { gaEvents } from '@/utils/ga.helpers';
import { startTour } from '@/utils/intercomHelpers';
import { loadLocaleData } from '@/utils/locale/locale.utils.ts';
import { ROUTE_IDS } from '@/utils/routes';

import { getAbsencesTypes } from './absences.js';
import { getHolidays, getMonthlyNorms } from './calendarData.js';
import { getChatSessionSignature } from './chat/chat';
import { getDevices } from './companymanage/devices.jsx';
import { getEmploymentConditions } from './companymanage/employmentConditions';
import { getPermissions, getRoles } from './companymanage/roles.js';
import { getCustomFieldsDefinitions } from './customFIelds/customFields.ts';
import { getEmployeesOrderForLocations } from './employeeSorting.js';
import { getAvailableExports } from './exports';
import {
  changeCurrentUserLanguageSuccesful,
  conn,
  connectionError,
  getEmployeesSuccesful,
  getLocationsSuccesful,
  pullAvaTypes,
  pullJobTitles,
  pullShiftblocks,
} from './index';
import { groupJobTitles } from './jobTitlesGrouping.js';
import { changeLocationFilterSuccess } from './locationFilter.js';
import { changeMultipleLocationFilterSuccess } from './multipleLocationFilter.js';
import { getCompanyMilestones } from './onboarding/companyMilestones';
import { getPayrollSettings } from './payrollSettings';
import { getRcpData } from './rcp.js';
import { getFiltersStateForNNReports } from './reports.js';
import { changeScheduleLocationFilterSuccess } from './schedule/scheduleLocationFilter';
import { getMultipleLocationSettings, getNotificationSettings } from './settings';
import { changeSingleEmployeeFilter } from './singleEmployeeFilter.js';
import { getTemplates } from './templates.js';
import {
  decreaseLoaderCounter,
  increaseLoaderCounter,
  resetLoaderCounter,
  setCurrentNavBarElementId,
  showModal,
} from './uiState.js';
import { updatePermissions } from './userPermissions.js';

const COOKIE_EXPIRY_DAYS = 7;

const messages = defineMessages({
  logInError: {
    id: 'login.logInError',
    defaultMessage: 'Błąd podczas logowania',
  },
  accountBlocked: {
    id: 'login.accountBlocked',
    defaultMessage: 'Konto jest zablokowane',
  },
  tooManyAuthRequest: {
    id: 'login.tooManyAuthRequest',
    defaultMessage: 'Podjęto zbyt dużo prób logowania w ciągu {timeLimit} sekund. Odczekaj i spróbuj ponownie',
  },
  incorrectPasswordOrName: {
    id: 'login.incorrectPasswordOrName',
    defaultMessage: 'Podano błędny adres email lub hasło',
  },
  googleRegisterErrorTitle: {
    id: 'login.googleOAuthErrorTitle',
    defaultMessage: 'Błąd rejestracji przez Google',
  },
  googleRegisterErrorDescription: {
    id: 'register.googleOAuthErrorDescription',
    defaultMessage: 'Konto z tym adresem email już istnieje. Zaloguj się lub wybierz inną metodę rejestracji.',
  },
  authErrorTitle: {
    id: 'login.authErrorTitle',
    defaultMessage: 'Ups!',
  },
  loginErrorDescription: {
    id: 'login.loginErrorDescription',
    defaultMessage: 'Nie udało się zalogować.',
  },
  logoutErrorDescription: {
    id: 'login.logoutErrorDescription',
    defaultMessage: 'Nie udało się wylogować.',
  },
});

export const authUserSuccesful = () => ({
  type: actionTypes.AUTH_USER_SUCCESSFUL,
});

export const authLogout = () => ({
  type: actionTypes.AUTH_USER_LOGOUT,
  payload: 'logoutClick',
});

export const authUserErrorMsg = requestPayload => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.AUTH_USER_ERROR,
    payload: requestPayload,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.logInError),
      description: intl.formatMessage(messages.incorrectPasswordOrName),
    },
  });
};

export const tooManyAuthRequestError = timeLimit => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.TOO_MANY_AUTH_REQUEST_ERROR,
    payload: {},
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.logInError),
      description: intl.formatMessage(messages.tooManyAuthRequest, {
        timeLimit,
      }),
    },
  });
};

export const storeUserIdLocally = userId => {
  setCookie('kadroUserId', userId, COOKIE_EXPIRY_DAYS);
};

export const getCurrentUserSuccesful = data => ({
  type: actionTypes.GET_CURRENT_USER_SUCCESFUL,
  payload: data,
});

export const getCurrentUserError = data => ({
  type: actionTypes.GET_CURRENT_USER_ERROR,
  payload: data,
});

export const getCurrentCompanySuccesful = data => ({
  type: actionTypes.GET_CURRENT_COMPANY_SUCCESFUL,
  payload: data,
});

export const getCurrentCompanyError = err => ({
  type: actionTypes.GET_CURRENT_COMPANY_ERROR,
  payload: err,
});

export const getRouteInitData = requestType => ({
  type: actionTypes.GET_ROUTE_INIT_DATA,
  payload: {
    pathname: '',
    requestType,
  },
});

export const requestedNewPassword = data => ({
  type: actionTypes.REQUEST_NEW_PASSWORD,
  payload: data,
});

export const settedNewPassword = data => ({
  type: actionTypes.SET_NEW_PASSWORD,
  payload: data,
});

export const authUserError = requestPayload => (dispatch, _, intl) => {
  if (requestPayload.err.response.status === 429) {
    const { timeLimit } = requestPayload.err.response.data;
    return dispatch(tooManyAuthRequestError(timeLimit));
  }
  if (requestPayload.err.response.status !== 404) {
    const { message } = requestPayload.err.response.data;
    const modal =
      message === 'User is inactive' || message === 'User is not invited'
        ? INACTIVE_USER_MODAL
        : APP_IN_MAINTANANCE_MODAL;

    dispatch({
      type: actionTypes.AUTH_USER_ERROR,
      notification: {
        type: 'error',
        title: intl.formatMessage(messages.authErrorTitle),
        description: intl.formatMessage(messages.loginErrorDescription),
      },
    });
    return dispatch(showModal(modal));
  }
  dispatch(authUserErrorMsg(requestPayload));
};

const logoutError = (e, intl) => {
  console.error('[logout] Failed to logout => ', e);
  return {
    type: actionTypes.AUTH_USER_LOGOUT_ERROR,
    notification: {
      title: intl.formatMessage(messages.authErrorTitle),
      description: intl.formatMessage(messages.logoutErrorDescription),
      type: 'error',
    },
  };
};

const handleLogout = (dispatch, resetLoaders) => {
  dispatch(saveAllViewSettings());
  dispatch(authLogout());
  deleteCookie('kadroUserId');
  conn.setUserId(null);
  if (resetLoaders) {
    dispatch(resetLoaderCounter());
  }
  browserHistory.push('/');
};

export const logoutUser =
  (resetLoaders = true, omitLogoutRequest = false) =>
  async (dispatch, getState, intl) => {
    try {
      if (!omitLogoutRequest) await conn.revokeToken();
      handleLogout(dispatch, resetLoaders);
    } catch (e) {
      if (e.response && (e.response.status === 401 || e.response.status === 403)) {
        handleLogout(dispatch, resetLoaders);
        return;
      }
      dispatch(logoutError(e, intl));
    }
  };

// This fetches all the data for 'employee'
export const getEmployeeInitData = () => dispatch => {
  dispatch(pullAvaTypes(conn.companyID, 'init'));
  dispatch(getChatSessionSignature());
  dispatch(pullJobTitles(conn.companyID, 'init'));

  const togetherCalls = [conn.getEmployees(conn.companyID, 'init'), conn.getLocations('init')];
  Promise.all(togetherCalls)
    .then(results => {
      const pulledEmployees = results[0].data.employees;
      const pulledLocations = results[1].data.locations;
      const currentYear = moment().year();
      const [previousYear, nextYear] = [currentYear - 1, currentYear + 1];

      if (pulledLocations[0]) {
        // Location filters fetch data on change and we don't want to fetch it again on init
        // so we just dispatch success action to set data on reducer state
        dispatch(getEmployeesSuccesful(pulledEmployees));
        dispatch(changeMultipleLocationFilterSuccess(pulledLocations.map(l => l.id)));
        dispatch(changeScheduleLocationFilterSuccess([pulledLocations[0].id]));
        dispatch(getLocationsSuccesful(pulledLocations));
        dispatch(changeLocationFilterSuccess(pulledLocations[0]));
        dispatch(getMultipleLocationSettings(pulledLocations, 'init'));
        dispatch(getAbsencesTypes());
        dispatch(getNotificationSettings());
        dispatch(getHolidays(previousYear, nextYear));

        dispatch(getAllViewSettings()).then(() => {
          dispatch(getRouteInitData('init'));
        });
        const employeeIds = pulledEmployees.map(employee => employee.id);
        dispatch(getContracts(employeeIds, undefined, undefined, 'init'));
      }
      dispatch(decreaseLoaderCounter('init'));
    })
    .catch(err => {
      if (err?.response?.data?.errorCode === LOCATION_NOT_FOUND) {
        dispatch(showModal(NO_LOCATIONS_MODAL));
      }
      dispatch(connectionError(err));
    });
};

// This fetches all the data for 'manager' and 'owner'
export const getFullInitData = () => (dispatch, getState) => {
  const { currentUser, userPermissions } = getState().reducer;
  const currentYear = moment().year();
  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1;
  dispatch(pullAvaTypes(conn.companyID, 'init'));
  dispatch(pullShiftblocks(conn.companyID, 'init'));
  dispatch(pullJobTitles(conn.companyID, 'init'));
  dispatch(getEmploymentConditions(conn.companyID, 'init'));
  dispatch(getTemplates('init'));
  dispatch(getPayrollSettings());
  dispatch(getAvailableExports());
  dispatch(getAbsencesTypes());
  dispatch(getNotificationSettings());
  dispatch(getHolidays(previousYear, nextYear)).then(() => {
    dispatch(getMonthlyNorms(previousYear, nextYear));
  });
  dispatch(getChatSessionSignature());
  if (userPermissions.permissions.includes(CUSTOM_FIELDS_SHOW)) dispatch(getCustomFieldsDefinitions());
  // After fetching employees and locations we pull the init schedule
  const togetherCalls = [conn.getEmployees(conn.companyID, 'init'), conn.getLocations('init')];
  Promise.all(togetherCalls)
    .then(async results => {
      let pulledEmployees = results[0].data.employees;
      if (currentUser.user.role === 'manager') {
        pulledEmployees = pulledEmployees.filter(employee =>
          employee.locations.some(location => currentUser.user.locations.map(l => l.id).includes(location.id)),
        );
        if (!pulledEmployees.length) pulledEmployees.push(currentUser.user);
      }
      // Employee code names
      if (
        userPermissions &&
        userPermissions.restrictions &&
        userPermissions.restrictions.includes(EMPLOYEES_CODE_NAMES)
      ) {
        pulledEmployees = pulledEmployees.map(employee => ({
          ...employee,
          first_name: 'Pracownik',
          last_name: `0000${String((employee.pin * 559859) % 10000)}`.slice(-4),
        }));
      }

      const employeeIds = pulledEmployees.map(employee => employee.id);
      await dispatch(getContracts(employeeIds, undefined, undefined, 'init'));
      const pulledLocations = results[1].data.locations;
      // TODO: fix this after employees enpoint is added
      // Explanation: on GET_CURRENT_USER_SUCCESFUL action we add currentUser to userEmployees
      // this is needed because we don't have endpoint for employees for employee
      // if we get empty array as employees list,
      // owner is not removed from userEmployees state and this breaks few things
      dispatch(getEmployeesSuccesful(pulledEmployees));
      dispatch(getDevices(conn.companyID));
      // This has to be set immedietaly, otherwise we get errors with parsing data
      if (pulledEmployees[0]) {
        dispatch(changeSingleEmployeeFilter(pulledEmployees[0]));
      }
      if (pulledLocations[0]) {
        // Location filters fetch data on change and we don't want to fetch it again on init
        // so we just dispatch success action to set data on reducer state
        dispatch(changeLocationFilterSuccess(pulledLocations[0]));
        dispatch(changeMultipleLocationFilterSuccess(pulledLocations.map(l => l.id)));
        dispatch(changeScheduleLocationFilterSuccess([pulledLocations[0].id]));
        dispatch(getLocationsSuccesful(pulledLocations));
        dispatch(getMultipleLocationSettings(pulledLocations, 'init'));
        dispatch(getEmployeesOrderForLocations(pulledLocations));
        dispatch(getAllViewSettings()).then(() => {
          dispatch(getRouteInitData('init'));
          dispatch(initScheduleViewState());
        });
        dispatch(getPaymentData());
        dispatch(groupJobTitles());
        dispatch(getFiltersStateForNNReports());
      }
      if (pulledLocations.length === 0) {
        dispatch(showModal(NO_LOCATIONS_MODAL));
      } else {
        dispatch(decreaseLoaderCounter('init'));
      }
    })
    .catch(err => {
      if (err?.response?.data?.errorCode === LOCATION_NOT_FOUND) {
        dispatch(showModal(NO_LOCATIONS_MODAL));
      }
      dispatch(connectionError(err));
    });
};

export const startLogRocket = () => async (_, getState) => {
  if (import.meta.env.VITE_NODE_ENV !== 'development') {
    const userId = getState().reducer.currentUser.user.id;
    const logRocket = await import('logrocket');
    const logRocketAppId = LOG_ROCKET_APP_ID;
    logRocket.default.init(logRocketAppId);
    logRocket.default.identify(userId);
  }
};

export const updateLogRocketData = () => async (_, getState) => {
  const userId = getState().reducer.currentUser.user.id;
  const logRocket = await import('logrocket');
  logRocket.default.identify(userId);
};

export const startSentry = () => async (_, getState) => {
  if (import.meta.env.VITE_NODE_ENV !== 'development') {
    const { currentUser, userPermissions } = getState().reducer;
    const { id, role, email, company_id } = currentUser.user;

    if (userPermissions.permissions.includes(SENTRY_ENABLE)) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [],
        initialScope: {
          release: appVersion,
          tags: { stack: import.meta.env.VITE_TARGET_API },
          user: { id, email, role, company_id, stack: import.meta.env.VITE_TARGET_API },
        },
      });
    }
  }
};

export const getCurrentCompany = requestType => dispatch =>
  conn
    .getCurrentCompany(requestType)
    .then(response =>
      dispatch(updatePermissions({ initialized: true }, response.data.company, requestType))
        .then(async () => {
          await dispatch(getCurrentCompanySuccesful(response.data));
          return response.data;
        })
        .catch(err => {
          console.error('Cannot check permissions for this user', err);
        }),
    )
    .catch(err => {
      const isPasswordExpired = err.response.status === 403 && err.response.data.passwordExpired;
      if (isPasswordExpired) {
        goToResetPasswordPage();
      } else {
        dispatch(logoutUser());
      }
      dispatch(getCurrentCompanyError(err));
      throw err;
    });

export const changeIntlLanguage = (locale, messages) => ({
  type: '@@intl/UPDATE',
  payload: {
    key: locale, // critical !!! - somehow it forces update
    locale,
    messages,
  },
});

export const setUserLanguage = lang => (dispatch, _state, intl) => {
  const messages = loadLocaleData(lang);
  intl.changeIntlConfig({ locale: lang, messages });
  dispatch(changeIntlLanguage(lang, messages));
  dispatch(changeCurrentUserLanguageSuccesful(lang));
};

export const getCurrentUser = requestType => dispatch =>
  new Promise(resolve => {
    conn
      .getCurrentUserInfo(requestType)
      .then(async response => {
        const { language } = response.data.user.settings;
        await dispatch(getCurrentUserSuccesful(response.data));
        await dispatch(setUserLanguage(language));
        resolve(response.data);
      })
      .catch(err => {
        const responseData = err?.response?.data;
        if (responseData?.user_id) {
          dispatch(
            getCurrentUserError({
              id: responseData.user_id,
              company_id: responseData.company_id,
              client_status: responseData.client_status,
            }),
          );
        }
      });
  });

export const getInitData = () => (dispatch, getState) =>
  dispatch(getCurrentUser('init')).then(async userData => {
    const accountType = userData.user.role;
    const companyData = await dispatch(getCurrentCompany('init'));

    if (!companyData) return;

    dispatch(getCompanyMilestones());
    dispatch(getRcpData(userData.user.id));
    dispatch(getLocationGroups());

    conn.setCompanyId(companyData.company.id);
    if (accountType === 'owner' || accountType === 'manager') {
      await dispatch(getFullInitData());
    } else {
      await dispatch(getEmployeeInitData());
    }

    const { userPermissions } = getState().reducer;

    if (userPermissions.permissions.includes(PERMISSIONS_VIEW_GET)) {
      await dispatch(getPermissions());
      await dispatch(getRoles());
    }
    if (userPermissions.permissions.includes(ENABLE_LOG_ROCKET)) {
      dispatch(startLogRocket());
    }
    if (userPermissions.permissions.includes(SENTRY_ENABLE)) {
      dispatch(startSentry());
    }

    if (accountType === 'owner' && companyData.company.status.status === TRIAL_STATUS) {
      browserHistory.push('/trial');
      dispatch(setCurrentNavBarElementId(ROUTE_IDS.TRIAL));
    }
  });

export const loginViaGhostLoginSuccess = {
  type: actionTypes.LOGIN_VIA_GHOST_LOGIN_SUCCESS,
};

export const requestAuthenticateUserFromCode = code => dispatch => {
  // increase init counter to enable fullscreenloader
  dispatch(increaseLoaderCounter('init'));
  conn
    .getAuthToken(code, 'init')
    .then(response => {
      dispatch(loginViaGhostLoginSuccess);
      const userId = response.data.user_id;
      conn.setUserId(userId);
      if (import.meta.env.VITE_NODE_ENV === 'development') storeUserIdLocally(userId);
      dispatch(getInitData());
      dispatch(authUserSuccesful());
    })
    .catch(err => {
      dispatch(decreaseLoaderCounter('init'));
      dispatch(authUserError({ code, err }));
    });
};

export const requestAuthenticateUser =
  (email, pass, rememberMe, increaseInitLoader = true) =>
  dispatch => {
    if (increaseInitLoader) {
      dispatch(increaseLoaderCounter('init'));
    }
    conn
      .authenticateClient(email, pass, 'init')
      .then(response => {
        const { user_id: userId, expires_at: expiresAt } = response.data;
        conn.setUserId(userId);
        dispatch(getInitData());
        dispatch(authUserSuccesful());
        if (rememberMe) {
          storeUserIdLocally(userId);
        }
        storeLoggedAccounts([{ email, userId, active: true, expires_at: expiresAt, isDefault: false }]);
      })
      .catch(err => {
        dispatch(decreaseLoaderCounter('init'));
        const isPasswordExpired = err.response.status === 403 && err.response.data.passwordExpired;
        if (isPasswordExpired) {
          goToResetPasswordPage();
          return;
        }
        if (err.response.data?.errorCode === AUTH_PROVIDER_MISMATCH) {
          startTour(USE_GOOGLE_OAUTH_LOGIN_TUTORIAL_ID);
          return;
        }
        dispatch(authUserError({ email, pass, rememberMe, err }));
      });
  };

export const checkCookiesForTokens = () => dispatch => {
  if (checkCookie() != null) {
    const userId = getCookie('kadroUserId');
    dispatch(increaseLoaderCounter('init'));
    conn.setUserId(userId);
    dispatch(getInitData());
    dispatch(authUserSuccesful());
  } else {
    return {
      type: actionTypes.NO_TOKEN_STORED,
    };
  }
  return undefined;
};

/**
 * This function signsUp the user on the plaform. For whichever reason it stores the cookie :)
 * TODO(Zbigniew): Please decsribe this
 * @param {string} email - Guess what?
 */
export const signUp = email => () => {
  setCookie('userEmail', email, COOKIE_EXPIRY_DAYS);
};

export const requestResetPassword = email => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .sendPasswordReset(email)
      .then(response => {
        dispatch(requestedNewPassword(response));
        resolve();
      })
      .catch(err => {
        dispatch(connectionError(err));
        reject();
      });
  });

export const setNewPassword = (uid, password, passwordConfirm) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .resetPassword(uid, password, passwordConfirm)
      .then(response => {
        dispatch(requestAuthenticateUser(response.data.email, password, true));
        browserHistory.push('/');
        dispatch(settedNewPassword(response));
        resolve(response);
      })
      .catch(err => {
        dispatch(connectionError(err));
        reject(err);
      });
  });

export const checkPasswordToken = uid => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .checkPasswordToken(uid)
      .then(response => {
        if (!response.data.found) {
          browserHistory.push({
            pathname: '/password',
            state: { tokenExpired: true },
          });
        }
        resolve(response);
      })
      .catch(err => {
        dispatch(connectionError(err));
        reject(err);
      });
  });

export const createOwner = registrationObject => dispatch => {
  const { purposes, ...dataToSave } = registrationObject;
  return conn
    .createOwner({ ...dataToSave, purpose: purposes })
    .then(response => {
      const companyDetails = {
        company_size: registrationObject.company_size,
        purposes,
      };
      if (registrationObject.industry) companyDetails.industry = registrationObject.industry;
      dispatch({
        type: actionTypes.SET_NEW_COMPANY_DETAILS,
        payload: companyDetails,
      });
      dispatch(requestAuthenticateUser(registrationObject.email, registrationObject.password, true));
      browserHistory.push('/trial');
      sendGTMEvent(registrationObject.phone ? 'event_done_tel_yes' : 'event_done_tel_no', {
        category: 'new_owner',
        label: `has_phone-${!!registrationObject.phone}`,
      });
      gaEvents.createdAccount(response.data.company.id, window.gaProducts, registrationObject.discount_code);
      setTimeout(() => {
        dispatch(updateLogRocketData());
      }, 10000);
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const checkEmail = email => () => conn.checkEmail(email);

export const initPaymentUnlock = clientStatus => ({
  type: actionTypes.GET_UNLOCK_PAYMENT_DATA,
  clientStatus,
});

export const getUnlockPaymentData = () => dispatch => {
  conn.getUnlockPaymentData().then(result => {
    dispatch({
      type: actionTypes.GET_UNLOCK_PAYMENT_DATA_SUCCESSFULL,
      payload: result.data,
    });
  });
};

export const initNotOwnerAccountBlocked = clientStatus => ({
  type: actionTypes.BLOCKED_NOT_OWNER_ACCOUNT,
  payload: clientStatus,
});
export const registerWithGoogleOAuthError = () => async (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.AUTH_USER_ERROR,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.googleRegisterErrorTitle),
      description: intl.formatMessage(messages.googleRegisterErrorDescription),
      options: {
        timeOut: 10000,
      },
    },
  });
};

export const getGoogleOAuthUserData = oauthUserData => ({
  type: actionTypes.GET_GOOGLE_OAUTH_USER_DATA,
  payload: oauthUserData,
});

export const moveToOnboardingStepTwoRegistrationByOAuth = accessToken => async dispatch => {
  try {
    const { data: oauthUserData } = await conn.getGoogleOAuthUserData(accessToken, 'blocking');
    dispatch(getGoogleOAuthUserData(oauthUserData));
  } catch (error) {
    if (error.response.statusCode === 400) dispatch(registerWithGoogleOAuthError());
    return;
  }

  browserHistory.push('/onboarding/stepTwo', { initialLoginWithOauth: true });
};

export const registerWithGoogleOAuth = companyData => async (dispatch, getState) => {
  const { googleOAuth } = getState().reducer;

  const { locale, ...restOfGoogleOAuth } = googleOAuth;
  let userId;
  dispatch(increaseLoaderCounter('init'));

  try {
    const { data } = await conn.registerWithGoogleOAuth({ ...restOfGoogleOAuth, ...companyData }, 'init');
    userId = data.user_id;
  } catch (err) {
    if (err.response.status === 400) dispatch(registerWithGoogleOAuthError());
    dispatch(decreaseLoaderCounter('init'));

    return;
  }

  conn.setUserId(userId);
  storeUserIdLocally(userId);
  dispatch(authUserSuccesful());
  await dispatch(getInitData());
  dispatch(decreaseLoaderCounter('init'));
  sendCreateAccountEvent();
};

export const loginWithGoogleOAuth = accessToken => async dispatch => {
  dispatch(increaseLoaderCounter('init'));

  let initialLogin, userId;

  try {
    const { data } = await conn.loginWithGoogleOAuth(accessToken);

    initialLogin = data.initial_login;
    userId = data.user_id;
  } catch (err) {
    dispatch(decreaseLoaderCounter('init'));
    return dispatch(authUserError({ err }));
  }

  conn.setUserId(userId);
  storeUserIdLocally(userId);
  dispatch(authUserSuccesful());
  await dispatch(getInitData(initialLogin));
  dispatch(decreaseLoaderCounter('init'));
};

export const loginWithEntraId = (accessToken, tenantId) => async dispatch => {
  let data;
  try {
    const response = await conn.loginWithEntraId(accessToken, tenantId);
    data = response.data;
  } catch (err) {
    return dispatch(authUserError({ err }));
  }
  const defaultAccount = data.find(item => item.is_default);
  const userId = (defaultAccount || data[0])?.user_id;

  const initialLogin = data.initial_login;
  conn.setUserId(userId);
  const loggedAccounts = data.map((account, index) => ({
    email: account.email,
    userId: account.user_id,
    active: defaultAccount ? account.is_default : index === 0,
    expiresAt: account.expires_at,
    firstName: account.first_name,
    lastName: account.last_name,
    companyName: account.company_name,
    locationNames: account.location_names,
    jobTitleNames: account.job_title_names,
    roleName: account.role_name,
    avatar: account.avatar,
    isDefault: account.is_default,
  }));
  storeUserIdLocally(userId);
  storeLoggedAccounts(loggedAccounts);
  dispatch(authUserSuccesful());
  await dispatch(getInitData(initialLogin));
};

export const setDefaultEntraIdAccount = userId => async dispatch => {
  try {
    await conn.setDefaultEntraIdAccount(userId);
  } catch (err) {
    dispatch(connectionError(err));
  }
};

export const unsetDefaultEntraIdAccount = userId => async dispatch => {
  try {
    await conn.unsetDefaultEntraIdAccount(userId);
  } catch (err) {
    dispatch(connectionError(err));
  }
};
